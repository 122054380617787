import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-context-menu-item-wrapper",
  ref: "menuItemRef",
  "data-type": "ContextMenuItem"
}
const _hoisted_2 = { class: "mx-item-row" }
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = {
  key: 1,
  class: "label"
}
const _hoisted_5 = { class: "mx-item-row" }
const _hoisted_6 = { class: "mx-shortcut" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VNodeRender = _resolveComponent("VNodeRender")!
  const _component_ContextMenuIconCheck = _resolveComponent("ContextMenuIconCheck")!
  const _component_ContextMenuIconRight = _resolveComponent("ContextMenuIconRight")!

  return (!_ctx.hidden)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.globalHasSlot('itemRender'))
          ? (_openBlock(), _createBlock(_component_VNodeRender, {
              key: 0,
              vnode: () => _ctx.globalRenderSlot('itemRender', _ctx.getItemDataForChildren())
            }, null, 8, ["vnode"]))
          : (_ctx.customRender)
            ? (_openBlock(), _createBlock(_component_VNodeRender, {
                key: 1,
                vnode: _ctx.customRender,
                data: _ctx.getItemDataForChildren()
              }, null, 8, ["vnode", "data"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass([
        'mx-context-menu-item',
        (_ctx.disabled ? 'disabled' : ''),
        (_ctx.keyBoardFocusMenu ? 'keyboard-focus' : ''),
        (_ctx.customClass ? (' ' + _ctx.customClass) : ''),
        (_ctx.showSubMenu ? 'open' : ''),
      ]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
                onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args)))
              }, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: _normalizeClass([
            'mx-icon-placeholder',
            _ctx.preserveIconWidth ? 'preserve-width': '',
          ])
                    }, [
                      _renderSlot(_ctx.$slots, "icon", {}, () => [
                        (_ctx.globalHasSlot('itemIconRender'))
                          ? (_openBlock(), _createBlock(_component_VNodeRender, {
                              key: 0,
                              vnode: () => _ctx.globalRenderSlot('itemIconRender', _ctx.getItemDataForChildren())
                            }, null, 8, ["vnode"]))
                          : (typeof _ctx.svgIcon === 'string' && _ctx.svgIcon)
                            ? (_openBlock(), _createElementBlock("svg", _mergeProps({
                                key: 1,
                                class: "icon svg"
                              }, _ctx.svgProps), [
                                _createElementVNode("use", { "xlink:href": _ctx.svgIcon }, null, 8, _hoisted_3)
                              ], 16))
                            : ((typeof _ctx.icon !== 'string'))
                              ? (_openBlock(), _createBlock(_component_VNodeRender, {
                                  key: 2,
                                  vnode: _ctx.icon,
                                  data: _ctx.icon
                                }, null, 8, ["vnode", "data"]))
                              : (typeof _ctx.icon === 'string' && _ctx.icon !== '')
                                ? (_openBlock(), _createElementBlock("i", {
                                    key: 3,
                                    class: _normalizeClass(_ctx.icon + ' icon '+ _ctx.iconFontClass + ' ' + _ctx.globalIconFontClass)
                                  }, null, 2))
                                : _createCommentVNode("", true)
                      ]),
                      (_ctx.checked)
                        ? _renderSlot(_ctx.$slots, "check", { key: 0 }, () => [
                            (_ctx.globalHasSlot('itemCheckRender'))
                              ? (_openBlock(), _createBlock(_component_VNodeRender, {
                                  key: 0,
                                  vnode: () => _ctx.globalRenderSlot('itemCheckRender', _ctx.getItemDataForChildren())
                                }, null, 8, ["vnode"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ContextMenuIconCheck)
                          ])
                        : _createCommentVNode("", true)
                    ], 2),
                    _renderSlot(_ctx.$slots, "label", {}, () => [
                      (_ctx.globalHasSlot('itemLabelRender'))
                        ? (_openBlock(), _createBlock(_component_VNodeRender, {
                            key: 0,
                            vnode: () => _ctx.globalRenderSlot('itemLabelRender', _ctx.getItemDataForChildren())
                          }, null, 8, ["vnode"]))
                        : (typeof _ctx.label === 'string')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.label), 1))
                          : (_openBlock(), _createBlock(_component_VNodeRender, {
                              key: 2,
                              vnode: _ctx.label,
                              data: _ctx.label
                            }, null, 8, ["vnode", "data"]))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.shortcut)
                      ? _renderSlot(_ctx.$slots, "shortcut", { key: 0 }, () => [
                          (_ctx.globalHasSlot('itemShortcutRender'))
                            ? (_openBlock(), _createBlock(_component_VNodeRender, {
                                key: 0,
                                vnode: () => _ctx.globalRenderSlot('itemShortcutRender', _ctx.getItemDataForChildren())
                              }, null, 8, ["vnode"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.shortcut), 1)
                        ])
                      : _createCommentVNode("", true),
                    (_ctx.showRightArrow)
                      ? _renderSlot(_ctx.$slots, "rightArrow", { key: 1 }, () => [
                          (_ctx.globalHasSlot('itemRightArrowRender'))
                            ? (_openBlock(), _createBlock(_component_VNodeRender, {
                                key: 0,
                                vnode: () => _ctx.globalRenderSlot('itemRightArrowRender', _ctx.getItemDataForChildren())
                              }, null, 8, ["vnode"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ContextMenuIconRight)
                        ])
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 34)),
        (_ctx.showSubMenu)
          ? _renderSlot(_ctx.$slots, "submenu", { key: 3 })
          : _createCommentVNode("", true)
      ], 512))
    : _createCommentVNode("", true)
}