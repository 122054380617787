
import { defineComponent, inject } from 'vue'
import { GlobalHasSlot, GlobalRenderSlot } from './ContextSubMenuWrapper.vue';
import { VNodeRender } from './ContextMenuUtils';

export default defineComponent({
  name: 'ContextMenuSperator',
  components: {
    VNodeRender
  },
  setup() {
      
    const globalHasSlot = inject('globalHasSlot') as GlobalHasSlot;
    const globalRenderSlot = inject('globalRenderSlot') as GlobalRenderSlot;

    return {
      globalHasSlot,
      globalRenderSlot,
    };
  },
})
