import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mx-context-menu-item-sperator mx-context-no-clickable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VNodeRender = _resolveComponent("VNodeRender")!

  return (_ctx.globalHasSlot('separatorRender'))
    ? (_openBlock(), _createBlock(_component_VNodeRender, {
        key: 0,
        vnode: () => _ctx.globalRenderSlot('separatorRender', {})
      }, null, 8, ["vnode"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1))
}