import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "menuBarContent",
  class: "mx-menu-bar-content"
}
const _hoisted_2 = {
  key: 1,
  ref: "menuBarContent",
  class: "mx-menu-bar-content"
}
const _hoisted_3 = ["onClick", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuBarIconMenu = _resolveComponent("MenuBarIconMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([ 
      'mx-menu-bar',
      _ctx.options.theme ?? '',
      _ctx.options.mini ? 'mini' : '',
    ]),
    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
  }, [
    _renderSlot(_ctx.$slots, "prefix"),
    (_ctx.options.mini)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "mx-menu-bar-item",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onItemClick(0, null)))
          }, [
            _createVNode(_component_MenuBarIconMenu)
          ])
        ], 512))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: _normalizeClass([
          'mx-menu-bar-item',
          item == _ctx.menuActive ? 'active' : '',
        ]),
              onClick: ($event: any) => (_ctx.onItemClick(key, item)),
              onMouseenter: ($event: any) => (_ctx.onItemEnter(key, item))
            }, _toDisplayString(item.label), 43, _hoisted_3))
          }), 128))
        ], 512)),
    _renderSlot(_ctx.$slots, "suffix")
  ], 34))
}